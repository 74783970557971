.signupBackground {
  background: -webkit-linear-gradient(
    rgba(14, 7, 68, 0.75), 
    rgba(0, 8, 99, 0.75)
    ), url("/media/illustrations/nyc-bridge.jpg");
  background: linear-gradient(
    rgba(14, 7, 68, 0.75), 
    rgba(0, 8, 99, 0.75)
    ), url("/media/illustrations/nyc-bridge.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.signupBackgroundOverlay {
  /* background: rgba(0, 0, 0, 0.2); */
  width: 100%;
  height: 100%;
}