.slightLeftMargin {
  margin-left: 6px !important;
}

.orangeLink {
  color: #f2711c;
}

.orangeLink:hover {
  color: #fd9e25;
}

.ui.button.round {
  border-radius: 3em;
}

html { 
  background: #f5f5f5;
  /* background: url(images/bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

body {
  background: none;
}

.ui.container.bottomBumper {
  padding-bottom: 5em;
}

.ui.circular.label.buttonCircleNotification {
  font-size: 0.4em;
  position: absolute;
  top: 0;
  right: 0; 
  margin: 4px 4px 0 0;
}

ol.ui.list li:before {
  color: rgba(162, 160, 160, 0.87);
  font-weight: bold;
}

p.hugeText {
  font-size: 1.2em;
}

i.inverted.circular.icon.heroIcon {
  background: none !important;
  color: #fff !important;
  border: 1px solid white;
  font-size: 2em;
  margin: 0;
}

.transactionDetailItem {
  font-size: 0.75em;
  font-weight: normal;
  margin-left: 6px;
  vertical-align: bottom;
}

.transactionDateItem {
  font-size: 0.75em;
  font-weight: normal;
  vertical-align: bottom;
}

.transactionDetailTags {
  margin-left: 6px;
}

.transactionDetailTags .ui.label {
  padding: 0.45em;
}

.ui.vertical.segment.white {
  background: white;
}