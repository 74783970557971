/* Remove default lines after menu items in navbar */
.ui.menu.boxMenu .item:before {
  display: none;
}

.ui.vertical.menu .item>.header.miniHeader {
  font-size: 0.8em;
}

/* Style the video: 100% width and height to cover the entire window */
.heroVideoContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.heroVideo {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

video[poster] {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.heroBackground {
  /* background: white; */
  background: none;
  /* background-image: url('/logos/logo_b.png'); */
  /* background-size: 200px;
  background-repeat: no-repeat;
  background-image: url('https://images.unsplash.com/photo-1495764506633-93d4dfed7c6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80');
  background-position: top;
  background-size: cover; */
  position: relative;
  overflow: hidden;
}

.heroOverlay {
  /* background: rgba(10, 0, 27, 0.5) !important; */
  background: -webkit-linear-gradient(rgba(14, 7, 68, 0.75), rgba(0, 8, 99, 0.75)), url("/media/illustrations/nyc-bridge.jpg");
  background: linear-gradient(rgba(14, 7, 68, 0.75), rgba(0, 8, 99, 0.75)), url("/media/illustrations/nyc-bridge.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.secondaryHeroOverlay {
  background: -webkit-linear-gradient(rgba(53, 0, 66, 0.75), rgba(0, 14, 66, 0.75)), url("/media/illustrations/nyc-empirestate.jpg");
  background: linear-gradient(rgba(53, 0, 66, 0.75), rgba(0, 14, 66, 0.75)), url("/media/illustrations/nyc-empirestate.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.darkImageOverlay {
  background: rgba(10, 0, 27, 0.5) !important;
}

.ui.horizontal.smallHeroDivider {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-size: 2em;
}

.quoteBackground {
  background: gray;
  background-image: url('https://images.unsplash.com/photo-1584036533827-45bce166ad94?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1789&q=80');
  background-position: center;
  background-size: cover;
}

.whiteFade {
  background: rgba(255, 255, 255, 0.90);
}